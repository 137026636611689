

















































































































































































































































































































































































































































































































































































































































































































































































































import { getMessageOptionsAnalyzeApi, getMessageSegmentAnalyzeApi, getAnalysisOpenSummaries } from '@/api/project'
import { OrderType } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { getPercentageVarietyStyle } from '@/utils/percentage-color'
import { computed, defineComponent, inject, onMounted, onUnmounted, reactive, Ref, ref, watch, nextTick } from '@vue/composition-api'
import { Drawer, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { isNil, cloneDeep, filter, isEqual } from 'lodash'
import Filters from './components/Filters.vue'
import WordCloud from '@/components/WordCloud.vue'
import { wordCloudTags, WordCloudType, AnalyzeTab, wordColors, branchWordCloudTags } from '@/utils/word-cloud'
import _ from 'lodash'
import { getHighlightText, showError } from '@/utils/common'

export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        Filters,
        WordCloud
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 0
        }
    },
    setup(props, { root, emit }) {
        const showDrawer = ref(props.value)

        const onClosed = () => {
            emit('input', showDrawer.value)
        }
        const showFilters = ref(false)

        const segments: any = inject('segments')

        const driver: any = inject('driver')
        const observer: boolean = inject('observer', false)

        const liveObj: any = inject("liveObj");
        const time = computed(() => {
            let remain = 0;
            if (
                liveObj?.timeInfo?.id == props.item?.message?.id &&
                liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
            ) {
                return (remain = liveObj?.timeInfo?.timeRemain);
            }
            return remain;
        });

        const observerInfo: any = inject('observerInfo', { isObserver: false })

        const roleMng: any = reactive({
            showConfig: computed(() => {
                return !observerInfo.isObserver
            }),
            allowShowWordCloud: computed(() => {
                const wordCloud = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return false
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable
                } else {
                    return wordCloud.nlp_settings.keywords_enable
                }
            }),
            allowShowAiAnalyse: computed(() => {
                const aiAnalyse = props.item?.message.ai_settings;
                if (isNil(aiAnalyse)) {
                    return false;
                }
                if (observerInfo.isObserver) {
                    return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable
                } else {
                    return aiAnalyse.ai_enable
                }
            }),
            allowVoting: computed(() => {
                try {
                    return props.item?.message.settings[0].recommend_enable
                } catch (error) {
                    return true
                }
            }),
            allowShow: (type: WordCloudType) => {
                const item = type === WordCloudType.Branch_Keywords ? 'keyword' : 'nlp'
                const res = observerInfo.shareInfo?.[item]
                return !observerInfo.isObserver || res
            }
        })

        const projectStore = useProjectStore()
        const { getAnalyzeSegments, updateAnalyzeSegments, getFilterSegments, updateFilterSegments } = projectStore

        const onShowWordCloudDialog = observerInfo.isObserver ? () => { } : inject<(message_entity_id: number) => void>('onShowWordCloudDialog')

        if (!onShowWordCloudDialog) {
            throw new Error('onShowWordCloudDialog is not provided');
        }

        const contentType = ref(AnalyzeTab.Answer)
        const wordCloudRef: Ref<any> = ref(null)

        const refreshData = async () => {
            if (showFilters.value) {
                await setCollection(analyzeObj.filterSegment.curFilter)
                await setCollection(wordCloudInfo.curSegment, true)
            } else {
                await Promise.all(analyzeObj.segments.map(async (segment: any, index: any) => {
                    if (analyzeObj.segmentAnswers.length > 0) {
                        analyzeObj.segmentAnswers[index] = await getSegmentAnswers(segment.id);
                    }

                    await setCollection(segment);
                }));
                await setCollection(wordCloudInfo.curSegment, true)
            }
            await handleSegmentChange(analyzeObj.curSegment)
        }

        const branchMngObj: any = reactive({
            tags: ['A', 'B', 'C', "D", "E"],
            curBranch: {},
            branchs: computed(() => {
                let bs: any[] = []
                if (!props.item?.message) {
                    return bs
                }
                bs = props.item.message.branchs
                return bs
            }),
            getTag: (branch: any) => {
                const index = branchMngObj.branchs.findIndex((b: any) => b.id == branch.id)
                return branchMngObj.tags[index]
            },
            isActive: (branch: any) => {
                return branchMngObj.curBranch.id == branch.id
            },
            changeBranch: async (branch: any) => {
                branchMngObj.curBranch = branch
                await refreshData()
            },
            getOpinionsMap: computed(() => {
                const opinionsMap: any = {}
                if (props.item?.collection) {
                    const opinions = branchMngObj.getOpinions
                    filter(Object.values(opinions), (o: any) => o.reply_index != -1).forEach((op: any) => (opinionsMap[String(op.reply_id)] = op))
                }
                return opinionsMap
            }),
            getOpinions: computed(() => {
                let opinions = {}
                let ops: any[] = []
                if (props.item?.collection) {
                    opinions = props.item?.collection?.result?.opinions
                    ops = Object.values(opinions).filter((op: any) => op.reply_index == branchMngObj.curBranch.id)
                }
                return ops
            }),
            getAnalysis: computed(() => {
                let opinions: any[] = props.item?.collection?.ai_analysis_result?.branch_summaries ? props.item.collection?.ai_analysis_result?.branch_summaries : []
                let ops: any[] = []
                if (props.item?.collection) {
                    ops = opinions.filter((op: any) => op.branch_id == branchMngObj.curBranch.id)
                }
                return ops
            }),
            getParticipants: computed(() => {
                return branchMngObj.getOpinions.length
            }),
            getCollection: computed(() => {
                const ops = branchMngObj.getOpinionsMap
                const collection = {
                    message_id: props.item?.collection?.message_id,
                    result: {
                        opinions: ops,
                        participants: branchMngObj.getOpinions.length
                    }
                }
                return collection
            })
        })


        const analyzeObj: any = reactive({
            loading: true,
            overallSegment: {
                id: 0,
                name: root.$t('all'),
                collection: branchMngObj.getCollection
            },
            curSegment: {
                id: 0,
                name: root.$t('all'),
                answers: [] as any[],
                analysis: [] as any[]
            },
            originSegments: computed(() => {
                const items = segments.value.map((segment: any) => {
                    const item = {
                        id: segment.id,
                        name: segment.name,
                        opinions: {}
                    }
                    return item
                })
                return [analyzeObj.overallSegment, ...items]
            }),
            segments: [] as any[],
            orderObj: {
                type: OrderType.None,
                curOrderSegment: {} as any,
                getType: (segment: any) => {
                    if (segment.id !== analyzeObj.orderObj.curOrderSegment.id) {
                        return OrderType.None
                    }
                    return analyzeObj.orderObj.type
                },
                clearAllOrder: () => {
                    analyzeObj.orderObj.type = OrderType.None
                    analyzeObj.orderObj.refreshOrder()
                },
                changeOrder: (segment: any, orderType?: OrderType) => {
                    if (isNil(segment.id)) {
                        return
                    }

                    if (segment.id != analyzeObj.orderObj.curOrderSegment.id) {
                        analyzeObj.orderObj.type = OrderType.None
                        analyzeObj.orderObj.curOrderSegment = segment

                    }

                    let keys = analyzeObj.curSegment.answers.map((answer: any) => answer.reply_id)
                    if (keys.length == 0) {
                        return
                    }

                    if (!orderType) {

                        if (analyzeObj.orderObj.type == OrderType.None) {
                            orderType = OrderType.DESC
                        } else if (analyzeObj.orderObj.type == OrderType.DESC) {
                            orderType = OrderType.ASC
                        } else if (analyzeObj.orderObj.type == OrderType.ASC) {
                            orderType = OrderType.None
                        }
                    }
                    analyzeObj.orderObj.type = orderType


                    const type = analyzeObj.orderObj.type
                    const opinions = analyzeObj.getOpinions(segment)
                    switch (type) {
                        case OrderType.None:
                            keys = keys.sort((a: any, b: any) => a - b)
                            break
                        case OrderType.DESC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a]?.agree_rate === opinions[b]?.agree_rate) {
                                    return a - b




                                } else {
                                    return opinions[b]?.agree_rate - opinions[a]?.agree_rate
                                }
                            })
                            break
                        case OrderType.ASC:
                            keys.sort((a: any, b: any) => {
                                if (opinions[a].agree_rate === opinions[b].agree_rate) {
                                    return (
                                        analyzeObj.curSegment.answers.find((answer: any) => answer.reply_id === a).reply_id -
                                        analyzeObj.curSegment.answers.find((answer: any) => answer.reply_id === b).reply_id
                                    )
                                } else {
                                    return opinions[a]?.agree_rate - opinions[b]?.agree_rate
                                }
                            })
                            break
                    }
                    analyzeObj.curSegment.answers.sort((a: any, b: any) => {
                        return keys.indexOf(a.reply_id) - keys.indexOf(b.reply_id)
                    })

                },
                refreshOrder: () => {
                    analyzeObj.orderObj.changeOrder(analyzeObj.orderObj.curOrderSegment, analyzeObj.orderObj.type)
                },
                canReset: computed(() => {
                    return analyzeObj.orderObj.type != OrderType.None
                })
            },
            getParticipants: (item: any) => {
                if (!item?.collection) {
                    return 0
                }
                return item.collection.result.participants
            },
            getOpinions: (segment: any) => {
                if (!segment?.collection) {
                    return {}
                }
                return segment?.collection?.result?.opinions
            },
            getPercentage: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return Math.round(opinion.agree_rate * 100)
            },
            getNumber: (segment: any, reply_id: number) => {
                const opinions = analyzeObj.getOpinions(segment)
                const opinion = opinions[reply_id]
                if (!opinion) {
                    return undefined
                }
                return opinion.agree + opinion.disagree + opinion.lose
            },
            filterSegment: {
                formIndex: 0,
                segments: [] as any[],
                curFilter: computed(() => {
                    if (analyzeObj.filterSegment.segments.length == 0) {
                        return null
                    }
                    return analyzeObj.filterSegment.segments[analyzeObj.filterSegment.formIndex]
                }),
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                selected: [] as any[],
                handleChange: async (options: any[]) => {
                    analyzeObj.filterSegment.curFilter.selected = options
                    analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(options)
                    await setCollection(analyzeObj.filterSegment.curFilter)
                    analyzeObj.orderObj.refreshOrder()
                },
                handleAnswerChange: async (options: any[]) => {
                    analyzeObj.filterSegment.curFilter.selected = options
                    analyzeObj.filterSegment.curFilter.name = analyzeObj.filterSegment.getName(options)
                    await setCollection(analyzeObj.filterSegment.curFilter, true)
                    analyzeObj.orderObj.refreshOrder()
                },
                reset: async () => {
                    analyzeObj.filterSegment.curFilter.selected = []
                    analyzeObj.filterSegment.curFilter.name = '--'
                    setCollection(analyzeObj.filterSegment.curFilter)
                    await analyzeObj.orderObj.clearAllOrder()
                }
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            },
            segmentAnswers: [] as any[],
            filterAnswers: computed(() => {
                let answers, analysis: any
                if (showFilters.value) {
                    if (analyzeObj.filterSegment.curFilter?.collection) {
                        answers = Object.values(analyzeObj.filterSegment.curFilter.collection.result?.opinions) ?? []
                        analysis = analyzeObj.filterSegment.curFilter.collection.ai_analysis_result?.branch_summaries ?? []
                        return { answers, analysis }
                    }
                } else {
                    if (analyzeObj.curSegment?.collection) {
                        answers = Object.values(analyzeObj.curSegment.collection.result?.opinions) ?? []
                        analysis = analyzeObj.curSegment.collection.ai_analysis_result?.branch_summaries ?? []
                        return { answers, analysis }
                    }
                }

                return { answers: [], analysis: [] }
            }),
        })


        const wordCloudInfo: any = reactive({
            isLoading: computed(() => {
                return props.item?.word_cloud?.loading
            }),
            list: [] as any[],
            shape: computed(() => {
                return props.item?.word_cloud?.nlp_settings?.shape ?? 'circle'
            }),
            colors: computed(() => {
                const color = props.item?.word_cloud?.nlp_settings?.color
                return wordColors.find((item) => item.color === color)?.list ?? ['#000000']
            }),
            currentTag: WordCloudType.Branch_Keywords,
            getTagClass: (tag: string) => {
                return tag === wordCloudInfo.currentTag ? 'active' : ''
            },
            typeTags: computed(() => {
                const tags = [] as any[]
                const wordCloud: any = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return tags
                }

                if (wordCloud.nlp_settings?.keywords_enable && roleMng.allowShow(WordCloudType.Branch_Keywords)) {
                    tags.push(branchWordCloudTags.find((tag) => tag.value === WordCloudType.Branch_Keywords))
                }
                if (wordCloud.nlp_settings?.nlp_enable && roleMng.allowShow(WordCloudType.Branch_Nlp)) {

                    tags.push(branchWordCloudTags.find((tag) => tag.value === WordCloudType.Branch_Nlp))
                }

                if (tags.length > 0) {
                    wordCloudInfo.currentTag = tags[0].value
                }
                return tags
            }),
            tagClick: (tag: any) => {
                wordCloudInfo.currentTag = tag.value
            },
            keywordTags: [] as any[],
            nlpTags: [] as any[],
            participants: computed(() => {
                if (props.item?.collection) {
                    return props.item.collection.result.participants
                }
                return 0
            }),
            opinions: computed(() => {
                let segment: any = {}
                if (showFilters.value) {
                    segment = wordCloudInfo.filterSegment.curFilter
                } else {
                    segment = wordCloudInfo.curSegment
                }
                if (segment?.collection) {
                    let ops: any[] = Object.values(segment?.collection.result?.opinions) ?? []
                    const retrieveMap = props.item?.word_cloud?.[wordCloudInfo.currentTag]
                    if (retrieveMap && retrieveMap[branchMngObj.curBranch.id]) {
                        const map = retrieveMap[branchMngObj.curBranch.id]
                        const tags: any[] = wordCloudInfo.getWordTags()
                        const idSet = new Set()
                        if (tags.length > 0) {
                            const keys = tags.map((tag) => tag.name)
                            keys.forEach((key) => {
                                const item = map[key]
                                if (item && item.frequency > 0) {
                                    item.replies.forEach((id: any) => idSet.add(Number(id)))
                                }
                            })
                            ops = ops.filter((op) => idSet.has(op.reply_id))
                        }
                    }
                    return ops
                }
                return []
            }),
            wordList: computed(() => {
                const wordCloud = showFilters.value ? wordCloudInfo.filterSegment.curFilter?.word_cloud : wordCloudInfo.curSegment?.word_cloud

                if (!wordCloud) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}`]
                if (!wordCloud || isNil(result)) {
                    return []
                }
                const curBranchResult = result[branchMngObj.curBranch.id]
                if (!curBranchResult) {
                    return []
                }
                const arr = Object.keys(curBranchResult).map((key) => {
                    const item = curBranchResult[key]
                    return {
                        name: key,
                        value: item.frequency
                    }
                })
                return arr
            }),
            downloadWord: () => wordCloudRef.value.downloadWord(),
            refreshWord: () => wordCloudRef.value.initViewData(),
            clickWord: (params: any) => {
                const { data } = params
                const tags: any[] = wordCloudInfo.getWordTags()
                if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
                    return
                }
                wordCloudInfo.getWordTags().push(data)
            },
            clearAllWork: () => {
                const tags = wordCloudInfo.getWordTags()
                tags.splice(0, tags.length)
            },
            clearWork: (tag: any, i: number) => {
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.splice(i, 1)
            },
            getWordTags() {
                return wordCloudInfo.currentTag == WordCloudType.Keywords ? wordCloudInfo.keywordTags : wordCloudInfo.nlpTags
            },
            getHighlightTags: (opinion: any) => {
                const wordCloud = props.item?.word_cloud
                if (isNil(wordCloud)) {
                    return []
                }
                const result: any = wordCloud[`${wordCloudInfo.currentTag}`]
                if (isNil(result)) {
                    return []
                }
                const curBranchResult = result[branchMngObj.curBranch.id]
                if (isNil(curBranchResult)) {
                    return []
                }
                const hightlightSet = new Set()
                const tags: any[] = wordCloudInfo.getWordTags()
                tags.forEach((tag) => {
                    const item = curBranchResult[tag.name]
                    if (item.replies.includes(opinion.reply_id)) {
                        item.highlight_tags?.forEach((tag: any) => {
                            hightlightSet.add(tag)
                        })
                    }
                })
                return Array.from(hightlightSet)
            },
            curSegment: {
                id: 0,
                name: root.$t('all'),
                collection: null as any,
                word_cloud: null as any,
                selected: [] as any[]
            },
            filterSegment: {
                curFilter: {
                    id: -1,
                    name: '--',
                    collection: null as any,
                    word_cloud: null as any,
                    selected: [] as any[]
                },
                getName: (selected: any[]) => {
                    if (selected.length == 0) {
                        return '--'
                    }
                    return selected
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')
                },
                handleChange: async (options: any[]) => {
                    wordCloudInfo.filterSegment.curFilter.selected = options
                    wordCloudInfo.filterSegment.curFilter.name = wordCloudInfo.filterSegment.getName(options)
                    await setCollection(wordCloudInfo.filterSegment.curFilter, true)
                },
                reset: async () => {
                    wordCloudInfo.filterSegment.curFilter.selected = []
                    wordCloudInfo.filterSegment.curFilter.name = '--'
                    setCollection(wordCloudInfo.filterSegment.curFilter, true)
                }
            },
            handleCommand: async (item: any) => {
                if (!item.segment) {
                    showFilters.value = true
                    wordCloudInfo.curSegment = wordCloudInfo.filterSegment.curFilter
                    await setCollection(wordCloudInfo.filterSegment.curFilter, true)
                } else {
                    showFilters.value = false
                    let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                        return segment.id === item.segment.id
                    })
                    if (segmentItem) {
                        const item = cloneDeep(segmentItem)
                        await setCollection(item, true)
                        wordCloudInfo.curSegment = item
                    }
                }
            },
            enableClass: (segment: any, itemSeg: any) => {
                if (segment.id >= 0) {
                    return segment.id == itemSeg?.id ? 'item-enable' : ''
                } else {
                    return itemSeg.id < 0 ? 'item-enable' : ''
                }
            }
        })
        wordCloudInfo.list = wordCloudInfo.wordList

        const getSegmentAnswers = async (segmentId: number) => {
            let answers, analysis
            // if (segmentId == 0) {
            //     answers=branchMngObj.getOpinions
            //     analysis=branchMngObj.getAnalysis
            //     return {answers,analysis}
            // }
            const res = await getMessageSegmentAnalyzeApi({
                uuid: props.item?.message.project_uuid,
                mid: props.item?.message.message_identifier,
                id: segmentId,
                display: 'none',
                driver: driver.value,
                branch_id: branchMngObj.curBranch.id,
                observer: observer,
                analysis_type: contentType.value
            })
            analysis = res[0]?.collection?.ai_analysis_result?.branch_summaries ? res[0].collection.ai_analysis_result?.branch_summaries : []
            answers = res[0]?.collection?.result?.opinions ? Object.values(res[0].collection.result.opinions) : []
            return { answers, analysis }
        }


        const setCollection = async (item: any, hidden = false) => {
            if (item.id == 0) {
                item.collection = branchMngObj.getCollection
                item.word_cloud = props.item?.word_cloud
            } else if (item.id > 0) {
                const res = await getMessageSegmentAnalyzeApi({
                    uuid: props.item?.message.project_uuid,
                    mid: props.item?.message.message_identifier,
                    id: item.id,
                    branch_id: branchMngObj.curBranch.id,
                    driver: driver.value,
                    display: hidden ? 'none' : '',
                    observer: observer,
                    analysis_type: contentType.value
                })
                if (res.length > 0) {
                    item.collection = res[0].collection
                    item.word_cloud = res[0].word_cloud
                }
            } else if (item.id < 0) {
                if (item.selected.length > 0) {
                    const optionIds = item.selected.map((option: any) => option.id)
                    const res: any = await getMessageOptionsAnalyzeApi({
                        uuid: props.item?.message.project_uuid,
                        mid: props.item?.message.message_identifier,
                        options: optionIds,
                        branch_id: branchMngObj.curBranch.id,
                        driver: driver.value,
                        display: hidden ? 'none' : '',
                        observer: observer
                    })
                    item.collection = res[0].collection
                    item.word_cloud = res[0].word_cloud
                } else {
                    item.collection = null
                    item.word_cloud = null
                }
            }
        }

        onMounted(async () => {
            await branchMngObj.changeBranch(branchMngObj.branchs[props.index])
            initData()
        })

        onUnmounted(() => {
            analyzeObj.segments.forEach((segment: any) => {
                segment.collection = null
            })
            analyzeObj.filterSegment.segments.forEach((segment: any) => {
                segment.collection = null
            })
            updateAnalyzeSegments(props.item?.message.project_uuid, analyzeObj.segments)
            updateFilterSegments(props.item?.message.project_uuid, analyzeObj.filterSegment.segments)
        })

        const initData = async () => {
            analyzeObj.curSegment.answers = branchMngObj.getOpinions

            const segs = getAnalyzeSegments(props.item?.message.project_uuid)
            analyzeObj.segments = segs && segs.length > 0 ? cloneDeep(segs) : analyzeObj.originSegments.slice(0, 3)


            if (analyzeObj.segments.length < 3) {
                const diff = 3 - analyzeObj.segments.length
                for (let i = 0; i < diff; i++) {
                    analyzeObj.segments.push({
                        name: '--',
                        collection: null
                    })
                }
            }


            const filterSegments = getFilterSegments(props.item?.message.project_uuid)
            analyzeObj.filterSegment.segments = filterSegments && filterSegments.length > 0 ? cloneDeep(filterSegments) : []
            if (analyzeObj.filterSegment.segments.length < 3) {
                for (let i = 0; i < 3; i++) {
                    analyzeObj.filterSegment.segments.push({
                        id: -1 - i,
                        name: '--',
                        selected: [],
                        collection: null
                    })
                }
            }

            analyzeObj.segmentAnswers = await Promise.all(
                analyzeObj.segments.map(async (segment: any) => {
                    const data = await getSegmentAnswers(segment.id);
                    return data;
                })
            )
            await setCollection(wordCloudInfo.curSegment, true)
            await refreshData()
        }


        const handleSegmentChange = async (segment: any) => {
            analyzeObj.curSegment.name = segment.name
            analyzeObj.curSegment.id = segment.id
            let segmentAnswers = await getSegmentAnswers(segment.id)
            analyzeObj.curSegment.answers = segmentAnswers.answers
            analyzeObj.curSegment.analysis = segmentAnswers.analysis
            analyzeObj.orderObj.refreshOrder()
        }

        const handleCommand = async (item: any) => {
            if (!item.segment) {
                const segment = analyzeObj.filterSegment.segments[item.index]
                analyzeObj.segments[item.index] = segment
                analyzeObj.filterSegment.formIndex = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                showFilters.value = true
                await setCollection(analyzeObj.segments[item.index])
            } else {
                showFilters.value = false
                let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                    return segment.id === item.segment.id
                })
                if (segmentItem) {
                    await setCollection(segmentItem)
                    item.index = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                    analyzeObj.segments.splice(item.index, 1, JSON.parse(JSON.stringify(segmentItem)))
                }

            }
        }

        const showAnswers = async (item: any) => {

            if (!item.segment) {
                const segment = analyzeObj.filterSegment.segments[item.index]
                analyzeObj.segments[item.index] = segment
                analyzeObj.filterSegment.formIndex = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                showFilters.value = true
                await setCollection(analyzeObj.filterSegment.curFilter, true)
                //await refreshData()
            } else {
                showFilters.value = false
                let segmentItem = analyzeObj.originSegments.find((segment: any) => {
                    return segment.id === item.segment.id
                })
                if (segmentItem) {
                    const cloneItem = cloneDeep(segmentItem)
                    item.index = item.index ?? analyzeObj.filterSegment.formIndex ?? 0
                    analyzeObj.segments.splice(item.index, 1, cloneItem)
                    //analyzeObj.segmentAnswers[item.index] = await getSegmentAnswers(item.segment.id)
                    await refreshData()
                    await setCollection(cloneItem)
                }
            }
        }

        const copy_text = (segment_index: any) => {
            root.$doCopy(analyzeObj.segmentAnswers[segment_index].map((i: any) => '"' + i.text.replace('"', '\"') + '"').join('\n'))
        }


        const copyMarkdownSummary = (index: any) => {
            let segmentAnswers = analyzeObj.segmentAnswers[index].analysis
            let summary = ''
            if (segmentAnswers.length > 0) {
                if (segmentAnswers[0].id == 0) {
                    summary = ''
                }
                if (segmentAnswers[0].status == 'done') {
                    summary = segmentAnswers[0].summary
                }

            }
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = root.$markdownToHtml(summary);

            // 从div中提取纯文本
            const textToCopy = tempDiv.textContent || '';
            root.$doCopy(textToCopy);
            tempDiv.remove();
        };
        const respondents = (token: any) => {
            if (props.item?.respondents) {
                const ops: any[] = props.item.respondents.filter((item: any) => item.token == token)
                if (ops.length > 0) {
                    const seenIds = new Set();
                    let segments = ops[0]
                    let token_segments = segments.token_segments ? segments.token_segments : [];
                    const uniqueData = token_segments.filter((item: any) => {
                        if (!seenIds.has(item.id)) {
                            seenIds.add(item.id);
                            return true;
                        }
                        return false;
                    });
                    segments.uniqueData = uniqueData
                    return segments
                } else {
                    return {
              link_id:'',
              uniqueData: []
            }
                }
            } else {
                return {
              link_id:'',
              uniqueData: []
            }
            }
        }


        watch(
            () => props.value,
            () => {
                showDrawer.value = props.value
            }
        )


        let isRefreshing = false;

        watch(
            () => props.item,
            async (value, old) => {
                if (isRefreshing) {
                    return;
                }
                isRefreshing = true;
                try {
                    analyzeObj.loading = !isEqual(value, old);
                    await refreshData();
                    if (showFilters.value) {
                        await setCollection(analyzeObj.filterSegment);
                    } else {
                        await Promise.all(
                            analyzeObj.segments.map(async (segment: any) => {
                                await setCollection(segment);
                            })
                        );
                        await setCollection(wordCloudInfo.curSegment, true);
                    }
                } finally {
                    isRefreshing = false;
                }
            }
        );



        watch(
            () => roleMng.allowShowWordCloud,
            (val) => {
                if (!val) {
                    contentType.value = AnalyzeTab.Answer
                }
            }
        )

        watch(
            () => roleMng.allowShowAiAnalyse,
            (val) => {
                if (!val) {
                    contentType.value = AnalyzeTab.Answer
                }
            }
        )


        watch(
            () => wordCloudInfo.wordList,
            (val, old) => {
                const isChange = JSON.stringify(val) !== JSON.stringify(old)
                if (isChange || wordCloudInfo.list.length === 0) {

                    wordCloudInfo.list = val
                }
            },
            { deep: true }
        )

        const refreshAnalysis = async (index: any) => {
            try {
                let segmentAnswers = analyzeObj.segmentAnswers[index].analysis
                if (segmentAnswers.length > 0) {
                    if (segmentAnswers[0].id == 0) return
                    const result = await getAnalysisOpenSummaries({ id: segmentAnswers[0].id })
                    analyzeObj.segmentAnswers[index].analysis = [result]
                    if (analyzeObj.segments[index].id > -1) {
                        analyzeObj.segmentAnswers[index] = await getSegmentAnswers(analyzeObj.segments[index].id);
                    }
                }
            } catch (error) {
                showError(error)
            }
        }

        const isRefreshAnalysis = (index: any) => {
            let segmentAnswers = analyzeObj.segmentAnswers[index].analysis
            if (segmentAnswers.length > 0) {
                if (segmentAnswers[0].id == 0) return false
                if (segmentAnswers[0].status == 'done' || segmentAnswers[0].status == 'error') return true
            }
            return false
        }

        return {
            OrderType,
            showDrawer,
            showFilters,
            branchMngObj,
            analyzeObj,
            handleCommand,
            handleSegmentChange,
            getPercentageVarietyStyle,
            onClosed,
            wordCloudRef,
            onShowWordCloudDialog,
            contentType,
            AnalyzeTab,
            wordCloudInfo,
            roleMng,
            getHighlightText,
            showAnswers,
            copy_text,
            refreshAnalysis,
            isRefreshAnalysis,
            time,
            copyMarkdownSummary,
            respondents
        }
    }
})
