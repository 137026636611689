



















































































import { computed, defineComponent, inject, nextTick, provide, reactive, ref } from '@vue/composition-api'
import AnalyzeBasicAskOpinion from '@/components/form-common-analyze/AnalyzeBasicAskOpinion.vue'
import CalculateTime from '@/components/CalculateTime.vue'

export default defineComponent({
    components: { AnalyzeBasicAskOpinion, CalculateTime },
    props: {
        item: {
            type: Object
        }
    },
    setup(props, { root }) {
        const head = inject('head')
        const messageItem: any = props.item
        provide('messageItem', messageItem)

        const drawerInfo = reactive({
            isAnalyze: false,
            showDrawer: false,
            onOpen: () => {
                drawerInfo.isAnalyze = true
                nextTick(() => {
                    drawerInfo.showDrawer = true
                })
            },
            onClose: () => {
                drawerInfo.showDrawer = false
                nextTick(() => {
                    drawerInfo.isAnalyze = false
                })
            },

        })

        const participants = computed(() => {
            if (props.item?.collection) {
                return props.item.collection.result.participants
            }
            return 0
        })

        const opinions = computed(() => {
            if (props.item?.collection) {
                return props.item.collection.result.opinions
            }
            return []
        })

        const observerInfo: any = inject("observerInfo", { isObserver: false });

        const roleMng: any = reactive({
        showConfig: computed(() => {
            return !observerInfo.isObserver;
        }),
        })

        const respondents = (token: any) => {
            if (props.item?.respondents) {
                const ops: any[] = props.item.respondents.filter((item: any) => item.token == token)
                if (ops.length > 0) {
                    const seenIds = new Set();
                    let segments = ops[0]
                    let token_segments=segments.token_segments?segments.token_segments:[];
                    const uniqueData = token_segments.filter((item: any) => {
                        if (!seenIds.has(item.id)) {
                            seenIds.add(item.id);
                            return true;
                        }
                        return false;
                    });
                    segments.uniqueData = uniqueData
                    return segments
                } else {
                    return {
              link_id:'',
              uniqueData: []
            }
                }
            } else {
                return {
              link_id:'',
              uniqueData: []
            }
            }
        }

        const copy_text = () => {
            let texts = []
            for (let i in opinions.value) {
                texts.push(opinions.value[i]);
            }
            root.$doCopy(texts.map((i: any) => '"' + i.text.replace('"', '\"') + '"').join('\n'))
        }

        return {
            head,
            participants,
            opinions,
            drawerInfo,
            copy_text,
            respondents,
            roleMng
        }
    }
})
