

















































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  provide,
  reactive,
  Ref,
  ref,
  watch,
  onBeforeUnmount
} from "@vue/composition-api";
import {
  ProjectStatus,
} from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { getAnalysisOpenSummaries, getConversationSingleMessagesApi } from '@/api/project'
import AnalyzeBranch from "@/components/form-common-analyze/AnalyzeBranch.vue";
import AnalyzeConceptBranch from "@/components/form-common-analyze/AnalyzeConceptBranch.vue";
import CalculateTime from "@/components/CalculateTime.vue";
import WordCloud from "@/components/WordCloud.vue";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";

import {
  branchWordCloudTags,
  WordCloudType,
  wordColors,
  AnalyzeTab,
  wordCloudTags,
} from "@/utils/word-cloud";
import { isNil } from "lodash";
import { getHighlightText, showError } from "@/utils/common";
import { storeToRefs } from 'pinia'

export default defineComponent({
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    AnalyzeBranch,
    AnalyzeConceptBranch,
    CalculateTime,
    WordCloud,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root }) {
    const timerId=ref()
    const head = inject("head");
    const projectUuId = computed(() => root.$route.params.projectUuId)
    const branchs = props.item?.message?.branchs ?? [];
    const branchSummaries = computed(() => {
      return props.item?.collection?.ai_analysis_result?.branch_summaries ?? []
    })
    const projectStore = useProjectStore()
    const { projectInfo } = storeToRefs(projectStore)
    const markdownSummary: Ref<any> = ref(null)

    const getMarkdownSummary = (id: any) => {

      let branch_summaries = branchSummaries.value.filter((item: any) => {
        return item.branch_id == id
      })
      return branch_summaries.length > 0 ? branch_summaries[0] : null
    }
    const observerInfo: any = inject("observerInfo", { isObserver: false });

    const roleMng: any = reactive({
      showConfig: computed(() => {
        return !observerInfo.isObserver;
      }),
      allowShowWordCloud: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return observerInfo.shareInfo?.keyword && wordCloud.nlp_settings.keywords_enable;
        } else {
          return (
            wordCloud.nlp_settings.keywords_enable 
          );
        }
      }),
      allowShowAiAnalyse: computed(() => {
        const aiAnalyse = props.item?.message.ai_settings;
        if (isNil(aiAnalyse)) {
          return false;
        }
        if (observerInfo.isObserver) {
          return observerInfo.shareInfo?.ai && aiAnalyse.ai_enable;
        } else {
          return aiAnalyse.ai_enable;
        }
      }),
      allowShow: (type: WordCloudType) => {
        const item = type === WordCloudType.Branch_Keywords ? "keyword" : "nlp";
        const res = observerInfo.shareInfo?.[item];
        return !observerInfo.isObserver || res;
      },
    });

    const onShowWordCloudDialog = observerInfo.isObserver
      ? () => { }
      : inject("onShowWordCloudDialog");

    const onShowAIAnalyzeDialog = observerInfo.isObserver
      ? () => { }
      : inject<(message_entity_id: number) => void>("onShowAIAnalyzeDialog");


    if (!onShowAIAnalyzeDialog) {
      throw new Error("onShowAIAnalyzeDialog is not provided");
    }

    const messageItem: any = props.item;
    provide("messageItem", messageItem);

    const wordCloudRef: Ref<any> = ref(null);

    const contentMngInfo = reactive({
      contentType: AnalyzeTab.SupportRate,
    });

    const branchMngObj: any = reactive({
      tags: ["A", "B", "C"],
      curBranch: branchs[0],
      branchs: branchs,
      getIndex: (branch: any) => {
        return branchs.findIndex((item: any) => item.id === branch.id);
      },
      getTag: (branch: any) => {
        const index = branchs.findIndex((b: any) => b.id == branch.id);
        return branchMngObj.tags[index];
      },
      isActive: (branch: any) => {
        return branchMngObj.curBranch.id == branch.id;
      },
      changeBranch: (branch: any) => {
        branchMngObj.curBranch = branch.branch;
        markdownSummary.value = getMarkdownSummary(branchMngObj.curBranch.id)
      },
      enableClass: (branch: any) => {
        return branchMngObj.curBranch.id == branch.id ? "item-enable" : "";
      },
      getOpinions: computed(() => {
        const opinions = props.item?.collection?.result?.opinions ?? [];
        const ops: any[] = Object.values(opinions).filter(
          (op: any) => op.reply_index == branchMngObj.curBranch.id
        );
        return ops;
      }),
      getParticipants: computed(() => {
        return branchMngObj.getOpinions.length;
      }),
      orderOpinions: (opinions: any) => {
        const ops: any[] = opinions ?? [];

        if (opinions.length > 1) {
          ops.sort((a, b) => {
            if (a.agree_rate > b.agree_rate) {
              return -1;
            } else if (a.agree_rate < b.agree_rate) {
              return 1;
            } else {
              if (a.id > b.id) {
                return -1;
              } else if (a.id < b.id) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        }
        return ops;
      },
      getAgreeRate: (opinion: any) => {
        const agree_rate = opinion.agree_rate ?? 0;
        return Math.round(agree_rate * 100);
      },
      respondents:(token: any) => {
        if(props.item?.respondents){
          const ops: any[]=props.item.respondents.filter((item: any) => item.token == token)
          if(ops.length>0){
            const seenIds = new Set();
            let segments=ops[0]
            let token_segments=segments.token_segments?segments.token_segments:[];
                    const uniqueData = token_segments.filter((item: any) => {
                        if (!seenIds.has(item.id)) {
                            seenIds.add(item.id);
                            return true;
                        }
                        return false;
                    });
            segments.uniqueData=uniqueData
            return segments
          }else{
            return 0
          }
        }else{
          return 0
        }
      }
    });

    const wordCloudInfo = reactive({
      isLoading: computed(() => {
        return props.item?.word_cloud?.loading;
      }),
      list: [] as any[],
      shape: computed(() => {
        return props.item?.word_cloud?.nlp_settings?.shape ?? "circle";
      }),
      colors: computed(() => {
        const color = props.item?.word_cloud?.nlp_settings?.color;
        return wordColors.find((item) => item.color === color)?.list ?? ["#000000"];
      }),
      currentTag: WordCloudType.Branch_Keywords,
      getTagClass: (tag: string) => {
        return tag === wordCloudInfo.currentTag ? "active" : "";
      },
      typeTags: computed(() => {
        const tags = [] as any[];
        const wordCloud: any = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return tags;
        }

        if (
          wordCloud.nlp_settings?.keywords_enable &&
          roleMng.allowShow(WordCloudType.Branch_Keywords)
        ) {
          tags.push(
            branchWordCloudTags.find((tag) => tag.value === WordCloudType.Branch_Keywords)
          );
        }
        if (
          wordCloud.nlp_settings?.nlp_enable &&
          roleMng.allowShow(WordCloudType.Branch_Nlp)
        ) {
          tags.push(
            branchWordCloudTags.find((tag) => tag.value === WordCloudType.Branch_Nlp)
          );
        }

        if (
          tags.length > 0 &&
          !tags.find((tag) => tag.value === wordCloudInfo.currentTag)
        ) {
          wordCloudInfo.currentTag = tags[0].value;
        }
        return tags;
      }),
      tagClick: (tag: any) => {
        wordCloudInfo.currentTag = tag.value;
      },
      keywordTags: [] as any[],
      nlpTags: [] as any[],
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result.participants;
        }
        return 0;
      }),
      opinions: computed(() => {
        if (props.item?.collection) {
          let opinions: any[] =
            Object.values(props.item.collection.result?.opinions) ?? [];
          let ops: any[] = Object.values(opinions).filter(
            (op: any) => op.reply_index == branchMngObj.curBranch.id
          );
          const retrieveMap = props.item?.word_cloud?.[wordCloudInfo.currentTag];
          if (retrieveMap && retrieveMap[branchMngObj.curBranch.id]) {
            const map = retrieveMap[branchMngObj.curBranch.id];
            const tags: any[] = wordCloudInfo.getWordTags();
            const idSet = new Set();
            if (tags.length > 0) {
              const keys = tags.map((tag) => tag.name);
              keys.forEach((key) => {
                const item = map[key];
                if (item && item.frequency > 0) {
                  item.replies.forEach((id: any) => idSet.add(Number(id)));
                }
              });
              ops = ops.filter((op) => idSet.has(op.reply_id));
            }
          }
          return ops;
        }
        return [];
      }),
      wordList: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (!wordCloud || isNil(wordCloud[wordCloudInfo.currentTag])) {
          return [];
        }
        const result: any = wordCloud[wordCloudInfo.currentTag];
        if (!result || isNil(result[branchMngObj.curBranch.id])) {
          return [];
        }
        const curBranchResult = result[branchMngObj.curBranch.id];
        const arr = Object.keys(curBranchResult).map((key) => {
          const item = curBranchResult[key];
          return {
            name: key,
            value: item.frequency,
          };
        });
        return arr;
      }),
      getHighlightTags: (opinion: any) => {
        const wordCloud = props.item?.word_cloud;
        if (isNil(wordCloud)) {
          return [];
        }
        const result: any = wordCloud[`${wordCloudInfo.currentTag}`];
        if (isNil(result)) {
          return [];
        }
        const curBranchResult = result[branchMngObj.curBranch.id];
        if (isNil(curBranchResult)) {
          return [];
        }
        const hightlightSet = new Set();
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.forEach((tag) => {
          const item = curBranchResult[tag.name];
          if (item.replies.includes(opinion.reply_id)) {
            item.highlight_tags?.forEach((tag: any) => {
              hightlightSet.add(tag);
            });
          }
        });
        return Array.from(hightlightSet);
      },
      downloadWord: () => wordCloudRef.value.downloadWord(),
      refreshWord: () => wordCloudRef.value.initViewData(),
      clickWord: (params: any) => {
        const { data } = params;
        const tags: any[] = wordCloudInfo.getWordTags();
        if (tags.length >= 6 || tags.some((v) => v.name == data.name)) {
          return;
        }
        wordCloudInfo.getWordTags().push(data);
        console.log(wordCloudInfo.opinions)
      },
      clearAllWork: () => {
        const tags = wordCloudInfo.getWordTags();
        tags.splice(0, tags.length);
      },
      clearWork: (tag: any, i: number) => {
        const tags: any[] = wordCloudInfo.getWordTags();
        tags.splice(i, 1);
      },
      getWordTags() {
        return wordCloudInfo.currentTag == WordCloudType.Branch_Keywords
          ? wordCloudInfo.keywordTags
          : wordCloudInfo.nlpTags;
      },
      showTabs: computed(() => {
        const wordCloud = props.item?.word_cloud;
        if (
          wordCloud &&
          (wordCloud.nlp_settings.keywords_enable || wordCloud.nlp_settings.nlp_enable)
        ) {
          return true;
        }
        contentMngInfo.contentType = AnalyzeTab.SupportRate;
        return false;
      }),
    });
    wordCloudInfo.list = wordCloudInfo.wordList;

    const drawerInfo = reactive({
      isAnalyze: false,
      showDrawer: false,
      onOpen: () => {
        drawerInfo.isAnalyze = true;
        nextTick(() => {
          drawerInfo.showDrawer = true;
        });
      },
      onClose: () => {
        drawerInfo.showDrawer = false;
        nextTick(() => {
          drawerInfo.isAnalyze = false;
        });
      },
    });

    const liveObj: any = inject("liveObj");
    const time = computed(() => {
      let remain = 0;
      // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
      //     remain = liveObj?.timeInfo?.timeRemain
      // }
      if (
        liveObj?.timeInfo?.id == props.item?.message?.id &&
        liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
      ) {
        return (remain = liveObj?.timeInfo?.timeRemain);
      }
      return remain;
    });

    const copy_text = () => {
      root.$doCopy(
        branchMngObj
          .orderOpinions(branchMngObj.getOpinions)
          .map((i: any) => '"' + i.text.replace('"', '"') + '"')
          .join("\n")
      );
    };

    const copyMarkdownSummary = () => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = root.$markdownToHtml(markdownSummary.value.summary);

      // 从div中提取纯文本
      const textToCopy = tempDiv.textContent || '';
        root.$doCopy(textToCopy);
      tempDiv.remove();
    };

    const refreshAnalysis = async () => {
      try {
        if (!!markdownSummary.value) {
          const result = await getAnalysisOpenSummaries({ id: markdownSummary.value.id })
          markdownSummary.value = result
          // if(projectInfo.value.status === ProjectStatus.End){
          //   timerId.value = setInterval(() => {
          //   getLiveConversationSingleMessage(props.item?.collection?.message_id)
          // }, 1000); 
          // }
        }
      } catch (error) {
        showError(error)
      }
    }

    watch(
      () => props.item?.collection?.ai_analysis_result,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old);
        if (isChange) {
          markdownSummary.value = getMarkdownSummary(branchMngObj.curBranch.id)
        }
      },
      { deep: true }
    );
    watch(
      () => roleMng.allowShowWordCloud,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );
    watch(
      () => roleMng.allowShowAiAnalyse,
      (val) => {
        if (!val) {
          contentMngInfo.contentType = AnalyzeTab.SupportRate;
        }
      }
    );

    watch(
      () => wordCloudInfo.wordList,
      (val, old) => {
        const isChange = JSON.stringify(val) !== JSON.stringify(old);
        if (isChange || wordCloudInfo.list.length === 0) {
          wordCloudInfo.list = val;
        }
      },
      { deep: true }
    );

    onMounted(() => {
      markdownSummary.value = getMarkdownSummary(branchMngObj.curBranch.id)
    });

    const getLiveConversationSingleMessage = async (messageId: string) => {
      if (!projectUuId.value) {
        return
      }
      try {
        const conversationMessageRes = await getConversationSingleMessagesApi({
          uuid: projectUuId.value,
          driver: projectInfo.value.dataDriver || '',
          messageId
        })
        if (conversationMessageRes && conversationMessageRes.length > 0) {
          const conversationMessage = conversationMessageRes[0]
          markdownSummary.value=conversationMessage.collection.ai_analysis_result
          if(!!markdownSummary.value){
            if (timerId.value&&(markdownSummary.value.status=='done'||markdownSummary.value.status=='error')) {
              clearInterval(timerId.value);
              timerId.value = undefined;
            }
          }else{
            if (timerId.value) {
              clearInterval(timerId.value);
              timerId.value = undefined;
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    
    onBeforeUnmount(() => {
      if (timerId.value) {
        clearInterval(timerId.value);
        timerId.value = undefined;
      }
    });

    return {
      head,
      branchMngObj,
      time,
      drawerInfo,
      wordCloudRef,
      wordCloudInfo,
      onShowWordCloudDialog,
      onShowAIAnalyzeDialog,
      AnalyzeTab,
      contentMngInfo,
      getHighlightText,
      roleMng,
      copy_text,
      markdownSummary,
      branchSummaries,
      getMarkdownSummary,
      refreshAnalysis,
      projectInfo,
      ProjectStatus,
      projectUuId,
      getLiveConversationSingleMessage,
      timerId,
      copyMarkdownSummary
    };
  },
});
