









































































































































































import { updateOrCreateSegmentApi } from '@/api/project'
import { EntityType, UpdateOrCreateSegmentDto } from '@/api/project/model'
import { useProjectStore } from '@/pinia/modules/project'
import { computed, defineComponent, inject, onMounted, reactive } from '@vue/composition-api'

export default defineComponent({
    props: {
        item: {
            type: Object
        },
        selectedOptions: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit, root }) {
        const conversationObj: any = inject('conversationObj')
        const baseObj: any = inject('baseObj')
        const segments: any = inject('segments')
        const projectStore = useProjectStore()
        console.log(segments.value)
        console.log(conversationObj)
        console.log(baseObj)
        const observer = inject('observer', false)

        const optionObj: any = reactive({
            isShowInput: false,
            inputName: '',
            allOptions: computed(() => {
                const options = [] as any[]
                optionObj.polls.forEach((item: any) => {
                    options.push(...item.message_entity.options)
                })
                optionObj.sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity.status == 'sended') {
                            options.push(item.message_entity.options)
                        }
                    })
                })
                return options
            }),
            selectedOptions: props.selectedOptions ?? [],
            isPollsCollapse: true,
            polls: [] as any[],
            isConversationsCollapse: true,
            sections: [] as any[],
            changeOption: (item: any, option: any) => {
                if (optionObj.isChecked(option)) {
                    optionObj.selectedOptions = optionObj.selectedOptions.filter((item: any) => {
                        return item.id !== option.id
                    })
                } else {
                    optionObj.selectedOptions.push(option)
                }

                emit('change', optionObj.selectedOptions)
            },
            isChecked: (option: any) => {
                return optionObj.selectedOptions.some((item: any) => {
                    return item.id === option.id
                })
            },
            reset: () => {
                optionObj.selectedOptions = []
                optionObj.isShowInput = false
                emit('reset')
            },
            canReset: computed(() => {
                return optionObj.selectedOptions.length > 0
            }),
            saveToSegment: async () => {
                if (optionObj.selectedOptions.length == 0) {
                    return
                }

                if (!optionObj.isShowInput) {
                    optionObj.isShowInput = true
                    optionObj.inputName = ''
                    console.log(`isShowInput: ${optionObj.isShowInput}`)
                    return
                }

                const name =
                    optionObj.inputName ??
                    optionObj.selectedOptions
                        .map((item: any) => {
                            return item.text
                        })
                        .join(',')

                const segment: UpdateOrCreateSegmentDto = {
                    uuid: props.item?.message.project_uuid,
                    name,
                    options: optionObj.selectedOptions.map((item: any) => {
                        return {
                            message_entity_id: Number(item.message_entity_id),
                            message_option_id: Number(item.id)
                        }
                    })
                }
                const data = await updateOrCreateSegmentApi(segment)
                projectStore.updateProjectSegments(props.item?.message.project_uuid)
                optionObj.reset()

                root.$message.success(root.$t('saveSuccess').toString())
            }
        })

        onMounted(() => {
            optionObj.polls = baseObj.polls
                .filter((item: any) => [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(item.message_entity.entity_type))
                .map((item: any) => ({
                    isCollapse: true,
                    ...item
                }))
                console.log(optionObj.polls,'optionObj.polls')
            conversationObj.sections.forEach((section: any) => {
                const newSection = {
                    isCollapse: true,
                    name: section.section_name,
                    sequence: section.sequence,
                    items: [] as any[]
                }
                section.items.forEach((item: any) => {
                    if ([EntityType.poll, EntityType.picture_poll].includes(item.message_entity.entity_type)) {
                        if (item.status == 'sended') {
                            newSection.items.push({
                                isCollapse: true,
                                ...item
                            })
                        }
                    }
                })
                if (newSection.items.length > 0) {
                    optionObj.sections.push(newSection)
                }
                

            })

        })

        return {
            EntityType,
            optionObj,
            observer
        }
    }
})
