








































































































import { defineComponent, reactive, ComputedRef, computed } from "@vue/composition-api";
import TalkBasicPoll from "@/components/form-common-talk/TalkBasicPoll.vue";
import TalkBasicAskOpinion from "@/components/form-common-talk/TalkBasicAskOpinion.vue";
import TalkSpeak from "@/components/form-common-talk/TalkSpeak.vue";
import TalkPoll from "@/components/form-common-talk/TalkPoll.vue";
import TalkImage from "@/components/form-common-talk/TalkImage.vue";
import TalkVideo from "@/components/form-common-talk/TalkVideo.vue";
import TalkRank from "@/components/form-common-talk/TalkRank.vue";
import TalkAskOpinion from "@/components/form-common-talk/TalkAskOpinion.vue";
import TalkAskExperience from "@/components/form-common-talk/TalkAskExperience.vue";
import TalkBranch from "@/components/form-common-talk/TalkBranch.vue";
import TalkUpload from "@/components/form-common-talk/TalkUpload.vue";
import { EntityType, ProjectStatus } from "@/api/project/model";
import AnalyzePoll from "@/components/form-common-analyze/AnalyzePoll.vue";
import AnalyzeAskOpinion from "@/components/form-common-analyze/AnalyzeAskOpinion.vue";
import AnalyzeBranch from "@/components/form-common-analyze/AnalyzeBranch.vue";
import AnalyzeAskExperience from "@/components/form-common-analyze/AnalyzeAskExperience.vue";
import AnalyzeAskRank from "@/components/form-common-analyze/AnalyzeAskRank.vue";

export default defineComponent({
  props: {
    projectInfo: {
      type: Object,
      default: {},
    },
    liveObj: Object,
  },
  components: {
    TalkBasicPoll,
    TalkBasicAskOpinion,
    TalkSpeak,
    TalkPoll,
    TalkImage,
    TalkVideo,
    TalkRank,
    TalkAskOpinion,
    TalkAskExperience,
    TalkBranch,
    TalkUpload,
    AnalyzePoll,
    AnalyzeAskOpinion,
    AnalyzeBranch,
    AnalyzeAskExperience,
    AnalyzeAskRank,
  },
  setup(props, { emit }) {
    const projectObj: ComputedRef<any> = computed(() => props.projectInfo);

    const isType = (type: EntityType) => {
      const pollTypeList = [
        EntityType.onboarding_poll,
        EntityType.onboarding_poll_multi,
        EntityType.onboarding_poll_picture,
        EntityType.onboarding_poll_picture_multi,
      ];
      const conversationPollTypeList = [
        EntityType.poll,
        EntityType.poll_multi,
        EntityType.picture_poll,
        EntityType.picture_poll_multi,
      ];
      const conversationUploadTypeList = [
        EntityType.poll,
        EntityType.poll_multi,
        EntityType.picture_poll,
        EntityType.picture_poll_multi,
      ];
      if (pollTypeList.includes(type)) {
        return EntityType.onboarding_poll;
      } else if (conversationPollTypeList.includes(type)) {
        return EntityType.poll;
      } else if (conversationUploadTypeList.includes(type)) {
        return EntityType.upload_image;
      } else {
        return type;
      }
    };

    const createBranch = (itemObj: any) => {
      emit(`createBranch`, itemObj);
    };

    return {
      projectObj,
      EntityType,
      isType,
      createBranch,
      ProjectStatus,
    };
  },
});
