


































































import { computed, defineComponent, inject, nextTick, reactive, ref } from '@vue/composition-api'
import AnalyzePoll from '@/components/form-common-analyze/AnalyzePoll.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { Divider } from 'element-ui'
import ImageCheckbox from './components/ImageCheckbox.vue'
import { EntityType, ProjectStatus } from '@/api/project/model'
import CalculateTime from '@/components/CalculateTime.vue'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'

export default defineComponent({
    components: {[Divider.name]: Divider,  TextCheckbox, ImageCheckbox, AnalyzePoll, CalculateTime },
    props: {
        item: {
            type: Object
        }
    },
    setup(props, { root, emit }) {
        const head = inject('head')
        const conversationObj: any = inject('conversationObj')
        const observer: boolean = inject('observer', false)
        const { projectInfo } = storeToRefs(useProjectStore())

        const drawerInfo = reactive({
            isAnalyze: false,
            showDrawer: false,
            onOpen: () => {
                drawerInfo.isAnalyze = true
                nextTick(() => {
                    drawerInfo.showDrawer = true
                })
            },
            onClose: () => {
                drawerInfo.showDrawer = false
                nextTick(() => {
                    drawerInfo.isAnalyze = false
                })
            }
        })

        const participants = computed(() => {
            if (props.item?.collection) {
                return props.item.collection.result.participants
            }
            return 0
        })

        const showBranch = computed(() => {
            return (
                !observer &&
                [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(props.item?.message?.message_type) &&
                // !conversationObj.hasBranchIds.includes(props.item?.message?.message_entity_id) &&
                projectInfo.value.status != ProjectStatus.End
            )
        })

        const isMult = computed(() => {
            return [EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture_multi].includes(props.item?.message?.message_type)
        })

        const createBranch = () => {
            emit(`createBranch`, props.item?.message)
        }

        return {
            head,
            participants,
            EntityType,
            showBranch,
            isMult,
            createBranch,
            drawerInfo
        }
    }
})
