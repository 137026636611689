var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-live-talk"},[(
      _vm.projectInfo.status === _vm.ProjectStatus.Published ||
      _vm.projectInfo.status === _vm.ProjectStatus.Start
    )?_c('div',{staticClass:"container-start"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("projectLive.startTip")))]),_c('div',{staticClass:"msg"},[_c('div',[_vm._v(_vm._s(_vm.$t("projectLive.startMsg1")))]),_c('div',[_vm._v(_vm._s(_vm.$t("projectLive.startMsg2")))]),_c('div',[_vm._v(_vm._s(_vm.$t("projectLive.startMsg3")))])])]):_vm._e(),(_vm.projectInfo.status === _vm.ProjectStatus.End)?_c('div',{staticClass:"container-start"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t("projectLive.endMsg")))])]):_vm._e(),[_c('div',{staticClass:"container-talk"},_vm._l((_vm.liveObj.pollList),function(poll,i){return _c('ul',{key:poll.message.message_entity_id,staticClass:"talk-box",attrs:{"id":("talk-" + (poll.message.message_entity_id)),"message-id":poll.message.message_identifier}},[_c('li',{staticClass:"talk-row"},[(_vm.isType(poll.message.message_type) == _vm.EntityType.onboarding_poll)?_c('TalkBasicPoll',{attrs:{"item":poll},on:{"createBranch":_vm.createBranch}}):_vm._e(),(
              _vm.isType(poll.message.message_type) == _vm.EntityType.onboarding_ask_opinion
            )?_c('TalkBasicAskOpinion',{attrs:{"item":poll}}):_vm._e()],1)])}),0)],[_c('div',{staticClass:"container-talk"},_vm._l((_vm.liveObj.conversationList),function(item,i){return _c('ul',{key:item.message.id,staticClass:"talk-box",attrs:{"id":("talk-" + (item.message.message_entity_id)),"message-id":item.message.message_identifier}},[_c('li',{staticClass:"talk-row"},[(_vm.isType(item.message.message_type) == _vm.EntityType.speak)?_c('TalkSpeak',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.poll)?_c('TalkPoll',{attrs:{"item":item},on:{"createBranch":_vm.createBranch}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.image)?_c('TalkImage',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.video)?_c('TalkVideo',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.rank)?_c('TalkRank',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.ask_opinion)?_c('TalkAskOpinion',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.ask_experience)?_c('TalkAskExperience',{attrs:{"item":item}}):_vm._e(),(_vm.isType(item.message.message_type) == _vm.EntityType.branch)?_c('TalkBranch',{attrs:{"item":item}}):_vm._e(),(
              [_vm.EntityType.upload_image, _vm.EntityType.upload_video].includes(
                _vm.isType(item.message.message_type)
              )
            )?_c('TalkUpload',{attrs:{"item":item}}):_vm._e()],1)])}),0)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }