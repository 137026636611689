


























































































































import { defineComponent, inject, ref,computed } from "@vue/composition-api";
import VideoDialog from "@/components/VideoDialog.vue";
export default defineComponent({
  components: {
    VideoDialog,
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {
    const head = inject("head");
    const videoDialogRef = ref();
    const messageOptions = computed(() => {
        if(props.item?.message.message_options.length==0&&!!props.item?.message.message_link){
            return [{link:props.item?.message.message_link}]
        }else{
         return props.item?.message.message_options;
        }
    });
   
    const showVideoDialog = (url: string) => {
      if (url) {
        videoDialogRef.value.open(url);
      }
    };
    return {
      head,
      videoDialogRef,
      showVideoDialog,
      messageOptions
    };
  },
});
