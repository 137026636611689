























































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  provide,
  reactive,
  ref,
} from "@vue/composition-api";
import AnalyzeAskExperience from "@/components/form-common-analyze/AnalyzeAskExperience.vue";
import AnalyzeConceptAskExperience from "@/components/form-common-analyze/AnalyzeConceptAskExperience.vue";
import { getPercentage } from "@/utils/common";
import CalculateTime from "@/components/CalculateTime.vue";
export default defineComponent({
  components: { AnalyzeAskExperience, CalculateTime, AnalyzeConceptAskExperience },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {
    const head = inject("head");

    provide("messageItem", props.item);

    console.log(props.item);

    const drawerInfo = reactive({
      isAnalyze: false,
      showDrawer: false,
      isAnalyzeConcept: false,
      showDrawerConcept: false,
      onOpen: () => {
        if (props.item?.message.test_group) {
          drawerInfo.isAnalyzeConcept = true;
          nextTick(() => {
            drawerInfo.showDrawerConcept = true;
          });
        } else {
          drawerInfo.isAnalyze = true;
          nextTick(() => {
            drawerInfo.showDrawer = true;
          });
        }
      },
      onClose: () => {
        if (props.item?.message.test_group) {
          drawerInfo.showDrawerConcept = false;
          nextTick(() => {
            drawerInfo.isAnalyzeConcept = false;
          });
        } else {
          drawerInfo.showDrawer = false;
          nextTick(() => {
            drawerInfo.isAnalyze = false;
          });
        }
      },
    });

    const categoryObj = reactive({
      categorys: computed(() => {
        let categorys = [];
        if (props.item?.message?.message_options) {
          if (!Array.isArray(props.item.message.message_options)) {
            categorys = JSON.parse(props.item.message.message_options);
          } else {
            categorys = props.item.message.message_options;
          }

          categorys.sort((a: any, b: any) => {
            return a.sequence - b.sequence;
          });
        }
        return categorys;
      }),
      getCategoryItem: (id: string) => {
        return props.item?.collection?.result?.category[id];
      },
      getReplys: (category: any) => {
        const categoryItem = categoryObj.getCategoryItem(category.id);
        return categoryItem?.participant_reply ?? [];
      },
      collapseList: [] as any[],
      isCollapse: (category: any) => {
        return categoryObj.collapseList.includes(category.id);
      },
      handleClick: (category: any) => {
        const categoryItem = categoryObj.getCategoryItem(category.id);
        if (categoryItem?.participant_reply?.length > 0) {
          categoryObj.collapseList = categoryObj.collapseList.includes(category.id)
            ? []
            : [category.id];
        }
      },
    });

    const participants = computed(() => {
      if (props.item?.collection) {
        return props.item.collection.result.participants;
      }
      return 0;
    });

    const percentage = (category: any) => {
      const categoryItem = categoryObj.getCategoryItem(category.id);
      const result = props.item?.collection?.result;
      let percentage = 0;
      if (result && categoryItem) {
        const num = categoryItem.participants ?? 0;
        const total = result.participants ?? 0;
        percentage = getPercentage(num, total);
      }
      return percentage;
    };

    const liveObj: any = inject("liveObj");
    const time = computed(() => {
      let remain = 0;
      // console.log(liveObj,props.item,"1223")
      // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
      //     remain = liveObj?.timeInfo?.timeRemain
      // }
      if (
        liveObj?.timeInfo?.id == props.item?.message?.id &&
        liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
      ) {
        return (remain = liveObj?.timeInfo?.timeRemain);
      }
      return remain;
    });

    return {
      categoryObj,
      participants,
      percentage,
      head,
      time,
      drawerInfo,
    };
  },
});
