














































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  provide,
  reactive,
  readonly,
  ref,
  set,
  shallowReadonly,
  watch,
  onMounted
} from "@vue/composition-api";
import AnalyzeAskRank from "@/components/form-common-analyze/AnalyzeAskRank.vue";
import AnalyzeConceptAskRank from "@/components/form-common-analyze/AnalyzeConceptAskRank.vue";
import { Divider } from "element-ui";
import CalculateTime from "@/components/CalculateTime.vue";
export default defineComponent({
  components: {
    [Divider.name]: Divider,
    AnalyzeAskRank,
    CalculateTime,
    AnalyzeConceptAskRank,
  },
  props: {
    item: {
      type: Object,
    },
  },
  setup(props, { root }) {

    const conceptContents = ref([]);
    const isOverflows = ref<boolean[]>([]);

    const getWidth = (el: any) => {
      const width  = el.clientWidth;
      return width || 0;
    };

    const scrollWidth = (el: any) => {
      const width = el.scrollWidth;
      return width || 0;
    };  

    onMounted(() => {
      // 使用nextTick确保DOM更新后再执行逻辑
      nextTick(() => {
        // 重新计算或检查溢出状态，这里可以调用一个方法来处理
        checkAndUpdateOverflows();
      });
    });

    // 定义一个方法来检查并更新溢出状态
    const checkAndUpdateOverflows = () => {
      isOverflows.value = conceptContents.value.map((contentEl:any) => {
        if (contentEl) {
          const outerWidth = getWidth(contentEl);
          const textWidth = scrollWidth(contentEl);
          return outerWidth < textWidth;
        }
        return false;
      });
    };

    const head = inject("head");
    const messageItem: any = props.item;
    
    provide("messageItem", messageItem);
    const drawerInfo = reactive({
      isAnalyze: false,
      isAnalyzeConcept: false,
      showDrawerConcept: false,
      showDrawer: false,
      onOpen: () => {
        if (props.item?.message.test_group) {
          drawerInfo.isAnalyzeConcept = true;
          nextTick(() => {
            drawerInfo.showDrawerConcept = true;
          });
        } else {
          drawerInfo.isAnalyze = true;
          nextTick(() => {
            drawerInfo.showDrawer = true;
          });
        }
      },
      onClose: () => {
        if (props.item?.message.test_group) {
          drawerInfo.showDrawerConcept = false;
          nextTick(() => {
            drawerInfo.isAnalyzeConcept = false;
          });
        } else {
          drawerInfo.showDrawer = false;
          nextTick(() => {
            drawerInfo.isAnalyze = false;
          });
        }
      },
    });

    const getRankScore = (ops: any[], id: number) => {
      let score = 0;

      const item = ops[id];
      const length = Object.keys(ops).length;
      if (item) {
        const votes = item.votes;
        if (votes && votes.length > 0) {
          for (let i = 0; i < length; i++) {
            const vs = votes.filter((item: any) => item.rank == i).length;

            score += (vs * (length - i) * (length - i)) / votes.length;
          }

          score = Math.round(Math.sqrt(score) * 100) / 100;
        }
      }
      return score;
    };
    provide("getRankScore", getRankScore);

    const talkMngObj = reactive({
      participants: computed(() => {
        if (props.item?.collection) {
          return props.item.collection.result?.participants ?? 0;
        }
        return 0;
      }),
      ranks: computed(() => {
        if (props.item?.collection) {
          const collection = props.item.collection;
          const ops = collection?.result?.ops;
          const ranks: any[] = props.item?.message?.message_options;
          if (ops) {
            ranks.forEach((rank) => {
              rank.score = getRankScore(ops, rank.id);
            });
            ranks.sort((a, b) => {
              return b.score - a.score;
            });
          }

          return ranks;
        }
        return [];
      }),
    });

    const ranks: any[] = props.item?.message?.message_options;
    const ops = props.item?.collection?.result?.ops;
    if (ops) {
      ranks.forEach((rank) => {
        rank.score = getRankScore(ops, rank.id);
      });

      ranks.sort((a, b) => {
        return b.score - a.score;
      });
    }

    const liveObj: any = inject("liveObj");
    const time = computed(() => {
      let remain = 0;
      // if (liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id) {
      //     remain = liveObj?.timeInfo?.timeRemain
      // }
      if (
        liveObj?.timeInfo?.id == props.item?.message?.id &&
        liveObj?.timeInfo?.message_entity_id == props.item?.message?.message_entity_id
      ) {
        return (remain = liveObj?.timeInfo?.timeRemain);
      }
      return remain;
    });

   


    return {
      head,
      talkMngObj,
      time,
      drawerInfo,
      conceptContents,
      isOverflows
    };
  },
});
